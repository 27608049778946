<template>
    <b-overlay :show="showLoader">
        <b-modal :title="$t('keys.products.product')" v-model="modalActive" no-close-on-backdrop size="lg">
            <template #modal-header>
                <b-tabs-header :tabs="getTitles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
            </template>
            <template #default>
                <div>
                    <validation-observer ref="validation">

                        <div v-if="activeTab === 0" :title="getTabTitle">
                            <b-form-group :label="$t('form_data.name')">
                                <validation-provider #default="{ errors }" :name="$t('form_data.name')"
                                                     rules="required">
                                    <b-form-input ref="name" autofocus v-model="addObject.name"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group :label="$t('general.category')">
                                <validation-provider #default="{ errors }" :name="$t('general.category')"
                                                     rules="required">
                                    <v-select v-model="addObject.product_category_id" appendToBody label="name"
                                              :options="productSubcategories" :reduce="item => item.id"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group :label="$t('general.additions')">
                                <v-select v-model="addObject.order_option_group_ids" appendToBody label="name" :options="productOrderOptions" :reduce="item => item.id" multiple/>
                            </b-form-group>
                            <b-form-group v-if="!editing" :label="$t('keys.sale_price')">
                                <validation-provider #default="{ errors }" :name="$t('keys.sale_price')"
                                                     rules="required|decimalCustom:2|mustBePositive:true">
                                    <b-input-group append="€">
                                        <b-form-input  type="number" :value="salePrice" v-on:input="changeSalePrice"/>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-row>
                                <b-col>
                                    <validation-provider #default="{ errors }" :name="$t('keys.color')">
                                        <colorSelector  v-model="addObject.color" :product-name="addObject.name"></colorSelector>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                            <div class="d-flex">
                                <div>{{$t("keys.products.disabled")}}</div>
                                <b-form-checkbox
                                    switch
                                    class="ml-2 red"
                                    v-model="addObject.disabled"
                                />
                            </div>
                        </div>
                        <div v-if="activeTab === 1 && $hasTierPermission(Tiers.Standard, Tiers.Premium)">
                            <b-form-group :label="$t('general.add_ingredient')">
                                <b-row>
                                    <b-col cols="6">
                                        <validation-provider #default="{ errors }"
                                                             :name="$t('general.add_ingredient')">
                                            <v-select label="name" :filter="fuseSearch" v-model="selected_ingredient"
                                                      appendToBody :options="newIngredientsFiltered"
                                                      @option:selected="ingredientChange"
                                                      :selectable="(ingredient) => !ingredient.disabled">
                                                <template #option="{ name, ingredient_code, disabled }">
                                                    <div :class="{'disabledIngredient': disabled}">{{ ingredient_code }} - {{ name }}</div>
                                                </template>

                                                <template #selected-option="{ name, ingredient_code }">
                                                    <div>{{ ingredient_code }} - {{ name }}</div>
                                                </template>
                                            </v-select>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col class="p-0" cols="4">
                                        <b-button class="w-100" variant="primary" @click="$refs.addModal.open()">
                                            <feather-icon icon="PlusIcon" class="mx-50"/>
                                            {{ $t('general.add_ingredient') }}
                                        </b-button>
                                    </b-col>
                                    <b-col class="p-0 pl-3" cols="2">
                                        <b-button  variant="outline-primary" @click="cloneIngredient(selected_ingredient)" :disabled="!selected_ingredient">
                                            <feather-icon icon="CopyIcon" class="mx-50"/>
                                        </b-button>
                                    </b-col>
                                </b-row>


                            </b-form-group>

                            <b-form-group :label="$t('form_data.quantity')">
                                <div style="display: flex; width: 100%">
                                    <validation-provider style="width: 100%" #default="{ errors }"
                                                         :name="$t('form_data.quantity')" :rules="getQuantityRules">
                                        <b-form-input v-model="selected_quantity"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider #default="{ errors }"
                                                         :name="$t('form_data.quantity')+'-select'">
                                        <v-select style="width: 150px; margin-left: 1rem"
                                                  v-model="selected_amount_type" :disabled="!selected_ingredient"
                                                  appendToBody label="name" :options="amount_types"/>
                                        <small style="width: 150px" class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </b-form-group>
                            <div class="d-flex justify-content-center ">
                                <b-button variant="primary" @click="addIngredient" class="w-25">
                                    <feather-icon icon="ArrowDownIcon" class=" justify-content-center"/>
                                </b-button>
                            </div>
                            <div v-if="addObject.ingredients">
                                <div>{{$t('general.ingredients')}}</div>
                                <Table ref="ingredientsTable" class="mt-1" :columnDefs="columnDefs"
                                       :rowData="addObject.ingredients" @deleteRow="removeIngredient"
                                       @edit="editIngredient"/>
                            </div>
                        </div>
                    </validation-observer>
                </div>

            </template>

            <template #modal-footer>
                <b-button variant="primary" @click="validate">
                    <span v-if="editing">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.edit') }}</span>
                    </span>
                    <span v-else>
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.save') }}</span>
                    </span>
                </b-button>
            </template>
        </b-modal>
        <AddIngredient ref="addModal" v-on:itemAdded="ingredientAdded" :ingredient_categories="ingredient_categories"/>
    </b-overlay>
</template>
<script>
    import Fuse from 'fuse.js'
    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol, BFormCheckbox, BOverlay, BInputGroup} from 'bootstrap-vue'
    //import {BButton, BModal, BTabs, BTab, BOverlay} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required, minValue } from '@validations'
    import vSelect from 'vue-select'
    import Table from '@/views/components/Table/Table'
    import AddIngredient from '@/views/Ingredient/AddIngredient.vue'
    import ColorSelector from '@/views/ColorSelector/ColorSelector.vue'
    import * as Sentry from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'
    import {
        getMoneyTypes,
        getPieceTypes,
        getVolumeTypes,
        getWeightTypes
    } from '@/libs/constants/ingredients.js'

    export default {
        components: {
            BTabsHeader,
            AddIngredient,
            BInputGroup,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BRow,
            BCol,
            BFormCheckbox,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            Table,
            ColorSelector,
            BOverlay
        },
        props: {
            vats: {
                type: Array
            },
            productSubcategories: {
                type: Array
            },
            ingredients: {
                type: Array
            },
            productOrderOptions: {
                type: Array
            }
        },
        data() {
            return {
                activeTab: 0,
                canAdd: false,
                clone: false,
                modalActive: false,
                newIngredientsFiltered: [],
                allIngredients: [],
                addObject: {
                    name: '',
                    sale_price: '',
                    color: '#545454',
                    vat_group_id: '',
                    product_category_id: '',
                    order_option_group_ids: [],
                    additional_options: {},
                    disabled: false,
                    ingredients: []
                },
                required,
                minValue,
                amount_types: [],
                salePrice: '',
                selected_ingredient: null,
                selected_quantity: 0,
                selected_amount_type: null,
                editing: false,
                ingredient_categories: [],
                showLoader: false
            }
        },
        methods: {
            handleClickTab(index) {
                this.activeTab = index
            },
            async ingredientAdded(id) {
                await this.reloadData()


                const item = this.allIngredients.find(ele => ele.id === id)

                if (item) {
                    this.selected_ingredient = item
                    this.ingredientChange()
                }


            },
            getConvertedQuantity(item) {
                if (!item || !item.quantity) return '/'

                let type = null
                if (item.selected_unit_type === 'mL' || item.selected_unit_type === 'dL' || item.selected_unit_type === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.selected_unit_type)
                } else if (item.selected_unit_type === 'g' || item.selected_unit_type === 'dag' || item.selected_unit_type === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.selected_unit_type)
                } else if (item.selected_unit_type === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.selected_unit_type)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.selected_unit_type)
                }

                if (!type) {
                    return
                }

                const numberOfDecimals = Math.log10(type.factor)

                const stock = Number(Number(item.quantity) / Number(type.factor)).toFixed(numberOfDecimals).replace(`.${'0'.repeat(numberOfDecimals)}`, '')

                return `${stock} ${item.selected_unit_type}`
            },
            ingredientChange() {
                if (this.selected_ingredient.packaging_unit === 'mL' || this.selected_ingredient.packaging_unit === 'dL' || this.selected_ingredient.packaging_unit === 'L') {
                    this.selected_amount_type = this.volume_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.volume_types
                } else if (this.selected_ingredient.packaging_unit === 'g' || this.selected_ingredient.packaging_unit === 'dag' || this.selected_ingredient.packaging_unit === 'kg') {
                    this.selected_amount_type = this.weight_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.weight_types
                } else if (this.selected_ingredient.packaging_unit === 'EUR') {
                    this.selected_amount_type = this.money_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.money_types
                } else {
                    this.selected_amount_type = this.piece_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.piece_types
                }
            },
            fuseSearch(options, search) {
                const fuse = new Fuse(options, {
                    keys: ['name', 'ingredient_code'],
                    shouldSort: true
                })
                return search.length ? fuse.search(search).map(({item}) => item) : fuse.list
            },
            cloneIngredient(data) {
                const sendData = JSON.parse(JSON.stringify(data))
                sendData.name = ''
                this.$refs.addModal.open(sendData, true)
            },
            open(data = null, clone = null) {
                this.clone = clone
                this.loadIngredientCategories()
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    if (this.addObject.ingredients === null) {
                        this.addObject.ingredients = []
                    }
                    this.editing = !clone
                    this.salePrice = JSON.parse(JSON.stringify(this.addObject.sale_price))
                    if (clone) {
                        this.addObject.name = ''
                        this.addObject.disabled = false
                        this.addObject.sale_price = JSON.parse(JSON.stringify(data.sale_price[0].sale_price))
                        this.addObject.ingredients = []
                        this.salePrice = JSON.parse(JSON.stringify(this.addObject.sale_price)) / 1000000
                    }
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        additional_options: {},
                        order_option_group_ids: [],
                        disabled: false,
                        ingredients: []
                    }
                    this.salePrice = ''
                }

                this.newIngredientsFiltered = this.ingredients.filter((ingredient) => {
                    return !this.addObject.ingredients.some((addedIngredient) => addedIngredient.id === ingredient.id)
                })
                this.allIngredients = this.ingredients
                this.selected_quantity = 0
                this.modalActive = true
                this.selected_ingredient = null
                this.canAdd = true
            },
            async loadIngredientCategories() {

                if (!this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    return
                }

                try {

                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/ingredient_subcategories/')
                    this.ingredient_categories = response.data ?? []

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async reloadData() {
                if (!this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    return
                }

                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/client/v1/ingredients/')
                    this.allIngredients = response.data ?? []
                    this.ingredients = response.data ?? []

                    this.newIngredientsFiltered = this.allIngredients.filter(elem => !this.addObject.ingredients.find(rm => (rm.id === elem.id)))

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.$refs.ingredientsTable.refresh()
                    this.showLoader = false
                }
            },
            validateIngredient() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        this.addIngredient()
                    }
                })
            },
            async addIngredient() {
                try {
                    const success = await this.$refs.validation.validate()

                    let tmp = this.selected_quantity

                    if (tmp) {
                        tmp = String(tmp).replace(',', '.')
                    }

                    if (success) {
                        const ingredient = {
                            'id': this.selected_ingredient.id,
                            'name': this.selected_ingredient.name,
                            'quantity': Number(tmp) * this.selected_amount_type.factor,
                            'selected_unit_type': this.selected_amount_type.name
                        }

                        this.addObject.ingredients.push(ingredient)
                        this.newIngredientsFiltered = this.newIngredientsFiltered.filter(ele => ele.id !== this.selected_ingredient.id)

                        this.selected_ingredient = null
                        this.selected_quantity = ''
                        this.selected_amount_type = null
                        this.$refs.validation.reset()
                    }
                } catch (err) {
                    this.$printError(this.$t('print.error.on_add'))
                }


            },
            removeIngredient(id) {
                for (let i = 0; i < this.addObject.ingredients.length; i++) {
                    if (this.addObject.ingredients[i].id === id) {
                        this.newIngredientsFiltered.push(this.allIngredients.filter((ingredient) => {
                            return ingredient.id === id
                        })[0])
                        this.addObject.ingredients.splice(i, 1)
                        break
                    }
                }
            },
            editIngredient(params) {
                this.addObject.ingredients[params.rowIndex].quantity = parseInt(params.newValue)
            },
            validate() {
                if (this.activeTab === 0) {
                    this.$refs.validation.validate().then((success) => {
                        if (success && this.canAdd === true) {
                            if (this.editing) this.edit()
                            else this.add()
                        }
                    })
                } else if (this.activeTab === 1) {
                    this.$refs.validation.validate().then((success) => {
                        if (success && this.canAdd === true) {
                            if (this.editing) this.edit()
                            else this.add()
                        }
                    })
                }
            },
            async add() {
                try {
                    const payload = {
                        name: this.addObject.name,
                        sale_price: this.addObject.sale_price,
                        color: this.addObject.color,
                        text_color: this.addObject.text_color,
                        product_category_id: this.addObject.product_category_id,
                        disabled: this.addObject.disabled,
                        ingredients: this.addObject.ingredients,
                        order_option_group_ids: this.addObject.order_option_group_ids
                    }
                    this.canAdd = false
                    const response = await this.$http.post('/api/client/v1/products/', payload)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        additional_options: {},
                        order_option_group_ids: [],
                        disabled: false,
                        ingredients: []
                    }
                    this.$emit('itemAdded', response.data.id)
                    this.modalActive = false
                    this.clone = false
                    this.salePrice = ''
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    const payload = {
                        id: this.addObject.id,
                        name: this.addObject.name,
                        color: this.addObject.color,
                        product_category_id: this.addObject.product_category_id,
                        additional_options: this.addObject.additional_options,
                        disabled: this.addObject.disabled,
                        ingredients: this.addObject.ingredients,
                        order_option_group_ids: this.addObject.order_option_group_ids
                    }
                    this.canAdd = false
                    await this.$http.put(`/api/client/v1/products/${this.addObject.id}`, payload)
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        order_option_group_ids: [],
                        disabled: false,
                        additional_options: ''
                    }
                    this.salePrice = ''
                    this.modalActive = false
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            getProductName(params) {
                if (params && this.allIngredients && this.allIngredients.length > 0) {
                    const tmp = this.allIngredients.find(x => x.id === params.id)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/products/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            changeSalePrice(val) {
                this.salePrice = JSON.parse(JSON.stringify(val))
                this.addObject.sale_price = Number(Number(Number(this.salePrice) * 1000000).toFixed(0))
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            columnDefs() {
                return [
                    {
                        headerName: this.$t('table_fields.name'),
                        field: 'name',
                        editable: false,
                        filter: true,
                        cellRenderer: (params) => this.getProductName(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.quantity'),
                        field: 'quantity',
                        filter: true,
                        editable: false,
                        cellRenderer: (params) => this.getConvertedQuantity(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'btnCellRenderer',
                        cellRendererParams: () => {
                            return {button: 'deleteBtn', noDelete: null}
                        },
                        maxWidth: 100,
                        minWidth: 100
                    }
                ]
            },
            Tiers() {
                return Tiers
            },
            getTitles() {
                return [{title: this.getTabTitle}, {title: this.$t('general.ingredients')}]
            },
            //used to set the max number of decimals for selected amount (conversion reasons check addIngredient() function)
            getQuantityRules() {
                if (this.selected_ingredient && this.selected_amount_type !== null) {
                    if (this.selected_amount_type.name === 'g' || this.selected_amount_type.name === 'mL') {
                        return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                    } else if (this.selected_amount_type.name === 'dag' || this.selected_amount_type.name === 'dL' || this.selected_amount_type.name === 'Kos') {
                        return 'required|decimalCustom:2|mustBePositive:true|min_number:0.0'
                    } else if (this.selected_amount_type.name === 'kg' || this.selected_amount_type.name === 'L') {
                        return 'required|decimalCustom:3|mustBePositive:true|min_number:0'
                    } else if (this.selected_amount_type.name === 'EUR') {
                        return 'required|decimalCustom:6|mustBePositive:true|min_number:0'
                    }

                    return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                } else {
                    return ''
                }
            },
            formatPrice() {
                return `${this.$numberRounding(this.salePrice, 2)}`
            },
            getTabTitle() {
                if (this.addObject.name) {
                    if (this.addObject.name !== '') {
                        return this.addObject.name
                    }
                }

                return this.$t('keys.products.product')
            }
        },
        mounted() {
            this.newIngredientsFiltered = this.ingredients
        }
    }
</script>
<style scoped>
.disabledIngredient {
  color: red;
  cursor: not-allowed !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<template>
    <b-modal ref="modal" :title="$t('used_at_modal.are_you_sure')" size="lg" @hide="close">
        <slot>
            {{ text }}
        </slot>

        <template #modal-footer>
            <div v-if="!okButton" class="d-flex justify-content-end">
                <div class="pr-2">
                    <b-button @click="cancel">
                        {{ $t('general.no') }}
                    </b-button>
                </div>
                <div>
                    <b-button @click="confirm" variant="danger">
                        {{ $t('general.yes') }}
                    </b-button>
                </div>
            </div>
            <div v-else>
                <b-button @click="cancel">
                    {{ $t('general.ok') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { BModal, BButton } from 'bootstrap-vue'

    export default {
        components:{
            BModal,
            BButton
        },
        data() {
            return {
                confirmTrue: false,
                text: 'Ali ste prepričani, da želite onemogočiti?',
                okButton: false
            }
        },
        methods:{
            cancel() {
                this.confirmTrue = false
                this.hide()
            },
            confirm() {
                this.confirmTrue = true
                this.hide()
            },
            close() {
                if (this.confirmTrue) {
                    this.$emit('confirm')
                } else {
                    this.$emit('cancel')
                }
                this.confirmTrue = false
            },
            show(okButton) {
                this.okButton = okButton
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>

<style scoped>
</style>